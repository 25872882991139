<template>
  <div id="app">
    <div class="home">
      <div class="home__header">
        <div class="home__header-background"></div>
        <img class="home__header-image" src="./assets/logo.png" alt="Logo">
      </div>
      <div class="home__grid">
        <room v-for="room in rooms" :data="room" v-bind:key="room.title"></room>
      </div>
    </div>
  </div>
</template>

<script>

import Room from "./components/Room";
export default {
  name: 'App',
  components: {Room},
  data() {
    return {
      rooms:[
        {
          title: "Escape Room Biel",
          url: "https://biel.mastermind.ch/",
          image: require("./assets/background_biel.jpg")
        },
        {
          title: "Escape Room Bern",
          url: "https://bern.mastermind.ch",
          image: require("./assets/background_bern.jpg")
        }
      ]
    }
  }
}
</script>