<template>
  <span class="fade-text" v-observe-visibility="{callback: visibilityChanged, once: true}">
    <span class="fade-text__character" v-for="char in text.length" v-bind:key="char" v-text="text[char - 1]"
          :class="[{'fade-text__character--fade-right' : direction === 1 && currentCharacter > char - 1}, {'fade-text__character--fade-left' : direction === -1 && currentCharacter < char - 1}]"
          :ref="`character${char - 1}`"></span>
  </span>
</template>

<script>
export default {
  name: "FadeText",
  props: {
    text: {
      type: String,
      required: true
    },
    direction: {
      type: Number,
      default: 1
    },
    animationTotalTimeMs: {
      type: Number,
      default: 1000
    },
  },
  data() {
    return {
      currentCharacter: -1
    }
  },
  computed: {
    animationStepsMs: function () {
      return this.animationTotalTimeMs / this.text.length
    }
  },
  mounted() {
    if (this.direction === -1) {
      this.currentCharacter = this.text.length
    }
  },
  methods: {
    visibilityChanged (isVisible) {
      if (isVisible) {
        this.showNextChar();
      }
    },
    showNextChar: function () {
      if (this.direction === 1) {
        if (this.currentCharacter < this.text.length) {
          this.currentCharacter++
          setTimeout(this.showNextChar, this.animationStepsMs);
        }
      } else if (this.direction === -1) {
        this.currentCharacter--
        setTimeout(this.showNextChar, this.animationStepsMs);
      }
    }
  }
}
</script>