<template>
  <div class="room" :style="`background-image: url(${data.image})`">
    <div class="room__background" :style="`background-image: url(${data.image})`"></div>
    <div class="room__overlay"></div>
    <a class="room__link-overlay" :href="data.url" :aria-label="data.title"></a>
    <div class="room__wrapper d-flex flex-column justify-content-end h-100">
      <h1 class="room__title">
        <span v-for="(word, key) in splitText" v-bind:key="word">
          <fade-text :text="word" :direction="(key % 2 === 0) ? -1 : 1"></fade-text>
          <br v-if="key < splitText.length - 1" />
        </span>
      </h1>
      <a class="room__link link" :class="{'room__link--show' : showLink}" :href="data.url"  v-observe-visibility="{callback: linkVisibilityChanged, once: true}"><fade-text text="enter"></fade-text>
        <svg class="link__circle" height="30" width="30">
          <circle cx="15" cy="15" r="14" stroke="white" stroke-width="2" fill="transparent" />
        </svg>
        <span class="link__line"></span>
      </a>
    </div>
  </div>
</template>

<script>
import FadeText from "./FadeText";
export default {
  name: "Room",
  components: {FadeText},
  props: {
    data: {
      type: Object
    }
  },
  data() {
    return {
      showLink: false
    }
  },
  computed: {
    splitText: function () {
      return this.data.title.split(" ")
    }
  },
  methods: {
    linkVisibilityChanged: function (visibility) {
      if (visibility) this.showLink = true
    }
  }
}
</script>

<style scoped>

</style>